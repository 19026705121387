import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading=(props)=>{
    return <div className="full-container">
        <div className="content text-center">
            <CircularProgress size={128} thickness={1.5} />
            <p className="text-center">
                {props.message}
            </p>
        </div>
    </div>
}
export default Loading;