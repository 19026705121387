import React,{Component} from 'react';
import Question from '../Question';
import ClientInformationRequest from '../ClientInformationRequest';
import ThankYouPage from '../ThankYouPage';
import Api from '../../api';
class Estimator extends Component
{
    state={
        index:0,
        answers:[],
        client:null,
        busy:false,
        lead:"",
        error:false
    }
    getMultiplier=()=>{
        let currentMultiplier=1;
        for(let i=0;i<this.state.index;i++)
        {
            this.state.answers[i].answers.forEach(answer=>{
                switch(answer.answer_effect)
                {
                    case "multiplier":
                    case "hours-multiplier":
                        currentMultiplier=parseFloat(answer.answer_factor);
                        break;
                    case "add-multiplier":
                    case "hours-add-multiplier":
                        currentMultiplier+=parseFloat(answer.answer_factor);
                        break;
                }
            })
        }
        return currentMultiplier;
    }
    componentWillMount()
    {
        if(localStorage.getItem("estimator-lead"))
            this.setState({lead:localStorage.getItem("estimator-lead")})
    }
    back=()=>{
        if(this.state.index>0)
            this.setState({index:this.state.index-1});
    }
    processAnswers=(answers)=>{
        if(this.state.answers.length>this.state.index)
            this.setState({answers:this.state.answers.map((answer,index)=>{
                if(index===this.state.index)
                {
                    answer.answers=answers;
                }
                return answer;
            }),index:this.state.index+1});
        else
            this.setState({answers:[...this.state.answers,{id:this.props.questions[this.state.index].id,answers}],index:this.state.index+1});
    }
    answer=(answers)=>{
        if(!this.props.preview)
            this.setState({busy:true,error:false},()=>Api.answer(this.props.id,this.props.questions[this.state.index].id,answers.map(answer=>answer.id),this.state.index,this.state.lead).then(lead=>{
                this.setState({lead,busy:false},()=>this.processAnswers(answers));
                localStorage.setItem("estimator-lead",lead);
            },error=>this.setState({busy:false,error:true})).catch(error=>this.setState({busy:false,error:true})));
        else
            this.processAnswers(answers);
    }
    submitInfo=(client)=>{
        if(!this.props.preview)
            this.setState({busy:true,error:false},()=>Api.submitInfo(this.props.id,this.state.lead,client).then(success=>{
                if(client.email) {
                    const _hsq = window._hsq = window._hsq || [];
                    if(_hsq) {
                        _hsq.push(["identify",{
                            email: client.email.toLowerCase()
                        }]);
                        _hsq.push(['trackPageView']);
                    }
                }
                this.setState({busy:false,client},()=>localStorage.clear());
            },error=>this.setState({busy:false,error:true})).catch(error=>this.setState({busy:false,error:true})));
        else
            this.setState({client});
    }
    render()
    {
        
        return <div className="estimator-container">
            
                {this.state.index>0&&<ul className="application-process">
                    {this.props.questions.map((question,index)=><li className={this.state.index===index?'current partial':(index<(this.state.index)?'complete':"pending")} key={`step-${index}`}>
                    <div>{index+1}</div>
                    </li>)}
                <li className={this.state.index>=this.props.questions.length&&!this.state.client&&(this.props.client||this.props.email||this.props.phone)?'current partial':((this.state.index>=this.props.questions.length?'complete':'pending'))}><div>{this.props.questions.length+1}</div></li>
                <li className={this.state.index>=this.props.questions.length&&(this.state.client||(!this.props.client&&!this.props.email&&!this.props.phone))?'complete':'pending'}><div>{this.props.questions.length+2}</div></li>
            </ul>}
            {this.state.index<this.props.questions.length&&<Question multiplier={this.getMultiplier()} error={this.state.error} disabled={this.state.busy} onAnswered={this.answer}  hasPrevious={this.state.index>0} onBack={this.back} {...this.props.questions[this.state.index]} userAnswers={this.state.answers.find(question=>question.id===this.props.questions[this.state.index].id)?this.state.answers.find(question=>question.id===this.props.questions[this.state.index].id).answers:[]} />}
            {this.state.index>=this.props.questions.length&&!this.state.client&&(this.props.client||this.props.email||this.props.phone)&&<ClientInformationRequest error={this.state.error} disabled={this.state.busy} onCancel={()=>this.setState({index:this.props.questions.length-1})} onCompleted={this.submitInfo} email={this.props.email} phone={this.props.phone} client={this.props.client} information_request_title={this.props.information_request_title} information_request_text={this.props.information_request_text} information_request_cta={this.props.information_request_cta} client_animation={this.props.client_animation} />}
            {this.state.index>=this.props.questions.length&&(this.state.client||(!this.props.client&&!this.props.email&&!this.props.phone))&&<ThankYouPage actions={this.props.actions} image={this.props.image} image_source={this.props.image_source} image_position={this.props.image_position} animation={this.props.thank_you_animation} thank_you_text={this.props.thank_you_text} thank_you_title={this.props.thank_you_title} image_border={this.props.image_border} cta={this.props.cta} />}
        </div>
    }
}
export default Estimator;