

export const isValidEmail=(email)=>
{
    if(!email)
        return false;
    return email.match(/^[a-z0-9A-Z]+[a-zA-Z0-9.\-_]*@[a-zA-Z0-9.\-_]+.[a-z0-9A-Z]{2,}$/)?true:false;    
}
export const isValidURL=(url)=>
{
    if(!url)
        return false;
    return url.match(/^http(s)?:\/\/[a-zA-Z0-9-]+.*\.[a-zA-Z0-9]+(\/.*)?$/)?true:false;    
}
export const isValidPassword=(password)=>
{
    if(!password)
        return false;
    return (password.length>=8&&password.match(/[A-Z]+/)&&password.match(/[0-9]+/)&&password.match(/[a-z]+/))?true:false;
}
export const isAlphaNumeric=(text)=>
{
    if(!text)
        return true;
    return text.match(/^[a-zA-Z0-9-_]+$/)?true:false; 
}
export const isValidIP=(ip)=>
{
    if(!ip)
        return false;
    return ip.match(/[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}(,[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})*/)?true:false;
}
export const isValidPhone=(phone)=>
{
    if(!phone)
        return false;
    return phone.toString().length>=10&&phone.toString().match(/^[0-9]+$/);
}
export const isValidRegExp=(regExp)=>
{
    if(!regExp)
        return false;
    try
    {
        var exp=new RegExp(regExp);
        return true;
    }
    catch(error)
    {
        return false;
    }
}
export const hasMinLength=(text,length)=>
{
    if(!text)
        return length===0;
    return text.length>=length;
}
export const isInteger=(number)=>
{
    if(!number)
        return number===0;
    return number.toString().match(/^[0-9]+$/)?true:false;
}
export const isCurrency=(number)=>
{
    if(!number)
        return number===0;
    return parseFloat(number.toString())&&number>=0;
}
export const isDateInput=(date)=>
{
    if(!date)
        return false;
    return date.toString().match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)?true:false;
}
export const isDate=(date)=>
{
    if(!date)
        return false;
    return date.toString().match(/^[0-9]{2}\/[0-9]{2}]\/[0-9]{4}$/)?true:false;
}
export const isValidFormula=(formula,variables)=>
{
    if(!formula)
        return false;
    let finalFormula=formula;
    variables.forEach(variable=>finalFormula=finalFormula.replace(new RegExp(variable,'g'),0));
    try
    {
        debugger;
        let result=eval(finalFormula);
        return result!=NaN;
    }
    catch(exception)
    {
        return false;
    }
}
export default 
{
    isValidEmail,
    isValidPassword,
    hasMinLength,
    isValidPhone,
    isValidURL,
    isValidIP,
    isInteger,
    isCurrency,
    isValidRegExp,
    isAlphaNumeric,
    isDateInput,
    isValidFormula,
    isDate
}