import axios from 'axios';
import Config from '../config';

export const _API=Config[`${Config.environment}_api`];

const getHeaders=(headers={})=>
{
    headers["Authorization"]=Config[`${Config.environment}_api_key`];
    return {headers:headers};
}

export default 
{
    get:function(endpoint)
    {
        return axios.get(`${_API+endpoint}`,getHeaders());
    },
    post:function(endpoint,params={})
    {
        return axios.post(`${_API+endpoint}`,params,getHeaders());
    },
    put:function(endpoint,params={})
    {
        return axios.put(`${_API+endpoint}`,params,getHeaders());
    },
    delete:function(endpoint)
    {
        return axios.delete(`${_API+endpoint}`,getHeaders());
    },
    patch:function(endpoint,params={})
    {
        return axios.patch(`${_API+endpoint}`,params,getHeaders());
    },
    uploadFormData:function(endpoint,formData)
    {
        return axios.post(`${_API+endpoint}`,formData,getHeaders({'Content-Type': 'multipart/form-data'})); 
    }
}