import React from 'react';
import Formatter from '../../utils/Formatter';
const Answer=(props)=>{
    return <div className="col-xl-4 col-lg-4 col-md-6 col-12 margin-bottom">
    <div className={`question img-${props.image_position} ${props.selected?'selected':''} ${props.disabled?'disabled':''}`} onClick={props.onSelected}>
        <div className={`image ${props.image_source} ${props.image_position} b-${props.image_border}`}>
            {props.image_source==="custom"&&props.image&&<img src={props.image} />}
        </div>
        <div className={`question-info ${props.image_position}`}>
        <h2>{props.answer_title}</h2>
        {props.answer_description&&<p>{props.answer_description}</p>}
        </div>
        <div className="clearfix"></div>
        {(props.answer_min_hours>0||props.answer_max_hours>0)&&<div className="divider">
        <div className="row">
            <div className="col-xl-6 col-5">
                <label>Estimated Hours</label>
            </div>
            <div className="col-xl-6 col-7">
                {props.answer_min_hours>0&&Math.floor(props.answer_min_hours*props.multiplier)}{props.answer_min_hours>0&&props.answer_max_hours>0&&" - "}{props.answer_max_hours>0&&Math.floor(props.answer_max_hours*props.multiplier)} Hours
            </div>
        </div>
        {props.answer_rate>0&&<div className="row">
            <div className="col-xl-6 col-5">
                <label>Estimated Cost</label>
            </div>
            <div className="col-xl-6 col-7">
            {props.answer_min_hours>0&&`$${Formatter.money(Math.floor(props.answer_min_hours*props.multiplier)*props.answer_rate,false)}`}{props.answer_min_hours>0&&props.answer_max_hours>0&&" - "}{props.answer_max_hours>0&&`$${Formatter.money(Math.floor(props.answer_max_hours*props.multiplier)*props.answer_rate,false)}`}
            </div>
        </div>}
        </div>}
    </div>
</div>
}
export default Answer;