import React,{Component} from 'react';
import Loading from '../../components/Loading';
import EstimatorNotFound from '../../components/EstimatorNotFound';
import {withRouter} from 'react-router-dom';
import Api from '../../api';
import TFEstimator from '../../components/TFEstimator';
class Estimator extends Component
{
    state={
        estimator:null,
        loading:false
    }
    getScripts=(html)=>{
        if(html)
        {
            let scripts=html.split("</script>");
            let result=[];
            scripts.forEach(script=>{
                let matches=script.match(/src="(.+?)"/);
                if(matches&&matches.length>=2)
                    result.push({type:"src",url:matches[1]});
                let content=script.substring(script.indexOf(">")+1);
                result.push({type:"content",content});
            })
            return result;
        }
        else
            return [];
        
    }
    componentDidMount()
    {
        if(this.props.match.params.id)
        {
            this.setState({loading:true},()=>Api.load(this.props.match.params.id,this.props.match.params.mode&&this.props.match.params.mode=="preview").then(estimator=>{
                if(estimator.scripts)
                {
                    let scripts=this.getScripts(estimator.scripts);
                    let scriptContent="";
                    scripts.forEach(script=>{
                        if(script.type=="src")
                        {
                            let s = document.createElement( 'script' );
                            s.setAttribute( 'src', script.url );
                            document.head.appendChild( s );
                        }
                        else
                            scriptContent+=script.content;
                    })
                    let s = document.createElement( 'script' );
                    s.innerHTML=scriptContent;
                    document.head.appendChild( s );
                }
                
                this.setState({estimator,loading:false})
            },error=>{
                console.log(error);
                this.setState({loading:false})
            }).catch(error=>{
                console.log(error);
                this.setState({loading:false})
            }))
        }
    }
    render()
    {
        return this.state.loading?<Loading message="Loading, please wait..." />:(!this.state.estimator?<EstimatorNotFound />:<TFEstimator id={this.props.match.params.id} preview={this.props.match.params.mode=="preview"} {...this.state.estimator} />)
    }
}
export default withRouter(Estimator);