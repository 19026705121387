import React,{Component} from 'react';
import {connect} from 'react-redux';

class ThankYouPage extends Component {
    
    componentDidMount() {
        if(this.props.actions.length) {
            const redirection=this.props.actions.find(a=>a.action==="redirect");
            if(redirection) {
                window.top.location.href = redirection.url;
            }
        }
    }
    render() {
        return <div className={`content animated ${this.props.animation}`}>
    <div className="row">
    <div className={`col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-10 offset-1 text-center thank-you  img-${this.props.image_position}`}>
    <div className={`image ${this.props.image_source} ${this.props.image_position} b-${this.props.image_border}`}>
            {this.props.image_source==="custom"&&this.props.image&&<img src={this.props.image} />}
        </div>
        <div className={`thank-you-info ${this.props.image_position}`}>
        {this.props.thank_you_title&&<h2>{this.props.thank_you_title}</h2>}
        {this.props.thank_you_text&&<p>{this.props.thank_you_text}</p>}
        </div>
        {this.props.cta&&this.props.cta.length>0&&<div className="row justify-content-center cta-buttons">

            {this.props.cta.map(cta=>
                <a target="_parent" className="branded" href={cta.link}>{cta.text}</a>)}
        </div>}
    </div>
    </div>
    </div>
    }
}


export default ThankYouPage;