import Http from '../utils/Http';
export default {
    load:function(id,preview=false)
    {
        return new Promise((resolve,reject)=>
        {
            Http.get(`/api/estimator/${id}/${preview?'preview':'lead'}`).then(result=>
                {
                    if(result&&!result.data.error)
                        resolve(result.data.data);
                    else
                        reject('Error');
                },error=>reject(error)).catch(error=>reject(error))
        });
    },
    answer:function(id,question,answers,step,lead="")
    {
        return new Promise((resolve,reject)=>
        {
            Http.put(`/api/rfq/${id}/${question}/${lead}`,{step,answers}).then(result=>
                {
                    if(result&&!result.data.error)
                        resolve(result.data.data);
                    else
                        reject('Error');
                },error=>reject(error)).catch(error=>reject(error))
        });
    },
    submitInfo:function(id,lead,info)
    {
        return new Promise((resolve,reject)=>
        {
            Http.post(`/api/rfq/${id}/${lead}`,{info}).then(result=>
                {
                    if(result&&!result.data.error)
                        resolve(result.data.data);
                    else
                        reject('Error');
                },error=>reject(error)).catch(error=>reject(error))
        });
    }
}