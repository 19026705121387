import React,{Component} from 'react';
import Answer from '../Answer';
class Question extends Component
{
    state={
        answers:[]
    }
    componentWillMount()
    {
        if(this.props.hasOwnProperty("userAnswers")&&(this.props.userAnswers instanceof Array)&&this.props.userAnswers.length>0)
            this.setState({answers:this.props.userAnswers});
    }
    componentWillReceiveProps(newProps)
    {
        if(newProps.hasOwnProperty("userAnswers")&&(newProps.userAnswers instanceof Array)&&newProps.userAnswers.length>0)
            this.setState({answers:newProps.userAnswers});
        else
            this.setState({answers:[]})
        window.scrollTo(0,0);
    }
    
    select=(answer)=>{
        if(!this.props.disabled)
        {
            if(this.state.answers.find(a=>a.id===answer.id))
            {
                this.setState({answers:this.state.answers.filter(a=>a.id!==answer.id)});
            }
            else
            {
                this.setState({answers:(this.props.multi&&answer.mixin)?[...this.state.answers.filter(existingAnswer=>existingAnswer.mixin),answer]:[answer]});
            }
        }
        
    }
    continue=()=>{
        if(this.state.answers.length>0)
            this.props.onAnswered(this.state.answers);
    }
    render()
    {
        return <div key={`question-${this.props.id}`} className={`content animated ${this.props.animation}`}>
                <h1>{this.props.question}</h1>
                <div className="row justify-content-center">
                    {this.props.answers.map(answer=><Answer multiplier={this.props.multiplier} key={answer.id} disabled={this.props.disabled} {...answer} selected={this.state.answers.find(a=>a.id===answer.id)?true:false} onSelected={()=>this.select(answer)} />)}
                </div>
                {this.props.error&&<div className="row">
                        <div className="col-12 text-center">
                            <div className="alert alert-danger animated fadeIn">There has been an error. Please try again.</div>
                        </div>
                    </div>}
                <div className="cta-container">
                    <div className="flex-buttons">
                    {this.props.hasPrevious&&<button onClick={this.props.onBack} disabled={this.props.disabled} className="default">Back</button>}
                    <button onClick={this.continue} disabled={this.state.answers.length===0||this.props.disabled} className={`branded ${this.props.busy?'loading':''}`}>{this.props.cta||"Continue"}</button>
                    </div>
                    
                </div>
            </div>
    }
    
}
export default Question;