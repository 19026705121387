import React from 'react';

const EstimatorNotFound=(props)=>{
    return <div className="full-container">
        <div className="content">
            <div className="container">
            <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-10 offset-1 text-center">
            <p className="text-center branded">
                We could not find the page you are looking for. If you consider this is an error, or simply have any questions for us, please do not hesitate to contact us.
            </p>
            <div className="clearfix"></div>
            <a href="https://topflightapps.com" className="branded">Contact Us</a>
            </div>
            </div>
            </div>
        </div>
    </div>
}
export default EstimatorNotFound;