export const money=(value,includeDecimals=true)=>
{
    if(!value)
        return includeDecimals?'0.00':'0';
    let roundedValue=parseFloat(value.toString()).toFixed(2);
        let pieces=roundedValue.split('.');

        let dollars="";
        for(let i=pieces[0].length-1;i>0;i--)
        {
            dollars=((pieces[0].length-i)%3===0&&i!=pieces[0].length-1)?`,${pieces[0].substring(i,i+1)}${dollars}`:`${pieces[0].substring(i,i+1)}${dollars}`;
        }
        return `${pieces[0].substring(0,1)}${dollars}${includeDecimals?'':`.${pieces[1]}`}`;
}
export const toInputDate=(date)=>{
    if(!date||!date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/))
        return "";
    else
    {
        let pieces=date.split('/');
        return `${pieces[2]}-${pieces[0]}-${pieces[1]}`;
    }
}
export const toStandardDate=(date)=>{
    debugger;
    if(!date||!date.match(/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/))
        return "";
    else
    {
        let pieces=date.split('-');
        return `${pieces[1]}/${pieces[2]}/${pieces[0]}`;
    }
}
export const moneyToNumber=(value)=>
{
    return value?Math.abs(parseFloat(value.toString().replace('$','').replace('%','').replace(new RegExp(",","g"),""))):0;
}
export const normalizeValue=(value)=>
{
    return value?value.replace(new RegExp("[^a-zA-Z0-9\.]","g"),""):"";
}

export default {
    money,
    normalizeValue,
    moneyToNumber,
    toInputDate,
    toStandardDate
}