import React,{Component} from 'react';
import Validators from '../../utils/Validators';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

class ClientInformationRequest extends Component
{
    state={
        email:"",
        name:"",
        phone:""
    }
    handleInputChange=(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    submit=()=>{
        this.setState({validating:true},()=>{
            // if((this.props.client&&this.state.name.length<2)||(this.props.email&&!Validators.isValidEmail(this.state.email))||(this.props.phone&&!Validators.isValidPhone(this.state.phone)))
            if((this.props.client&&this.state.name.length<2)||(this.props.email&&!Validators.isValidEmail(this.state.email)))
                return;
            else
                this.props.onCompleted({name:this.state.name,phone:this.state.phone,email:this.state.email});
        });
    }
    render()
    {
        return <div className={`content animated ${this.props.client_animation}`}>
            <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-10 offset-1 text-center branded">
            {this.props.information_request_title&&<h1>{this.props.information_request_title}</h1>}
            {this.props.information_request_text&&<p className="text-center">
            {this.props.information_request_text}
            </p>}
            {this.props.client&&<div className="form-group"><input disabled={this.props.disabled} className="form-control" type="text" placeholder="Enter your Name" value={this.state.name} onChange={this.handleInputChange} name="name" required />
            {this.state.validating&&this.state.name.length<2&&<div className="input-error animated shake">Please enter your name</div>}
            </div>}
            {this.props.email&&<div className="form-group"><input disabled={this.props.disabled} className="form-control" name="email" type="email" placeholder="Enter your Email Address" value={this.state.email} onChange={this.handleInputChange} required />
            {this.state.validating&&!Validators.isValidEmail(this.state.email)&&<div className="input-error animated shake">Please enter a valid Email Address</div>}
            </div>}
            {this.props.phone && 
                <div>
                    <PhoneInput
                        placeholder="Enter your Phone Number"
                        defaultCountry="US"
                        value={this.state.phone}
                        onChange={phone => {
                            this.setState({phone});
                        }}
                        disabled={this.props.disabled}
                        name="phone"
                        required/>
                    <br/>
                    {/* <input disabled={this.props.disabled} className="form-control" name="phone" type="tel" placeholder="Enter your Phone Number" value={this.state.phone} onChange={(e)=>{
                        if(!e.target.value||Validators.isInteger(e.target.value))
                            this.handleInputChange(e);
                    }} required /> */}
                    {/* {this.state.validating&&!Validators.isValidPhone(this.state.phone)&&<div className="input-error animated shake">Please enter a valid Phone Number</div>} */}
                </div>
            }
            {this.props.error&&<div className="row">
                        <div className="col-12 text-center">
                            <div className="alert alert-danger animated fadeIn">There has been an error. Please try again.</div>
                        </div>
                    </div>}
            <div className="flex-buttons">
            <button disabled={this.props.disabled} onClick={this.props.onCancel} className="default">
                        Back
                    </button>
                    <button disabled={this.props.disabled||(this.state.validating&&((this.props.client&&this.state.name.length<2)||(this.props.email&&!Validators.isValidEmail(this.state.email))))} className="branded loading" onClick={this.submit}>
                        {this.props.information_request_cta||"Submit"}
                    </button>
            </div>
            </div>
            
            </div>
            
            
        </div>
    }
}
export default ClientInformationRequest;